import React, { useContext, useEffect, useRef, useState } from "react";
import { ExamTrainingContext } from "../../ExamTraining/ExamTraining";
import { PrivateClassContext } from "../../PrivateClasses/PrivateClasses";

import styles from "../ModalForms.module.css";
import cx from "classnames";
import { translations } from "../translations";
import LanguageContext from "../../LanguageContext";

const CheckboxWithInputWithoutStyles: React.FC<any> = ({
  customType = "private-class",
  form: { setFieldValue, values },
  label,
  value,
  field,
  id,
  isRadio,
  disabled,
}) => {
  const { language } = useContext(LanguageContext);
  const { setDefaultContext } = useContext(PrivateClassContext);
  const { setDefaultContext: setDefaultContextExamTraining } =
    useContext(ExamTrainingContext);

  const [inputValue, setInputValue] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const onChangeCheckBox = (e: any) => {
    setIsChecked(e.target.checked);

    if (
      values[field.name]?.length > 0 &&
      values[field.name]?.some((elem: string) =>
        elem.includes(translations[language].other)
      )
    ) {
      setFieldValue(field.name, "");
      contextSetter((prevState: any) => ({
        ...prevState,
        [field.name]: "",
      }));
    }
  };

  const contextSetter =
    customType === "private-class"
      ? setDefaultContext
      : setDefaultContextExamTraining;

  const setMultipleValues = (e: any) => {
    if (!values[field.name]) {
      setFieldValue(field.name, [
        `${translations[language].other} - ${e.target.value}`,
      ]);
      contextSetter((prevState: any) => ({
        ...prevState,
        [field.name]: [`${translations[language].other} - ${e.target.value}`],
      }));

      return;
    }

    setFieldValue(field.name, [
      ...values[field.name],
      `${translations[language].other} - ${e.target.value}`,
    ]);
    contextSetter((prevState: any) => ({
      ...prevState,
      [field.name]: [
        ...values[field.name],
        `${translations[language].other} - ${e.target.value}`,
      ],
    }));
  };

  return (
    <>
      <label htmlFor={label + id}>
        <input
          id={label + id}
          checked={isChecked}
          type="checkbox"
          onChange={onChangeCheckBox}
        />
        {label}
      </label>
      {isChecked && (
        <input
          className={cx(styles.formInput, styles.formInputWithCheckbox)}
          type="text"
          name={id}
          defaultValue={inputValue}
          onBlur={setMultipleValues}
        />
      )}
    </>
  );
};

export default CheckboxWithInputWithoutStyles;
