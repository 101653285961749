import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import AvailableCourse from "../AvailableCourses/AvailableCourse";
import LanguageContext from "../LanguageContext";
import { translations } from "../ModalForms/translations";
import ExamTrainingForm from "./ExamTrainingForm";

import styles from "../PrivateClasses/PrivateClasses.module.css";

import {
  getExamTrainingPersons,
  getExamTrainingValues,
  renderTimetable,
} from "../PrivateClasses/PrivateClassesHelpers";

export const ExamTrainingContext = createContext<any>({});

const ExamTraining = () => {
  const { language } = useContext(LanguageContext);
  const [examTrainingsPrices, setExamTrainingsPrices] = useState<any>({});
  const [currentPrice, setCurrentPrice] = useState(0);
  const [selectedPersons, setSelectedPersons] = useState(0);
  const [defaultContext, setDefaultContext] = useState({
    whichLanguageYouWant: [],
    whatLearn: [],
    yourCurrentLevel: "",
    coursePlace: "",
    howOften: "1",
    howOftenDays: [],
    howOftenHours: "",
    howPersonA1B1: "",
    howPersonB2C2: "",
    startDate: "",
    endDate: "",
  });

  const fetchPrices = useCallback(async () => {
    const prices = await fetch(
      "/api/getExamTrainingPrices?language=" + language
    ).then((respond) => respond.json());

    setExamTrainingsPrices(prices);
  }, []);

  useEffect(() => {
    fetchPrices();
  }, [fetchPrices]);

  useEffect(() => {
    if (!getExamTrainingValues(defaultContext)) {
      setCurrentPrice(0);
      setSelectedPersons(0);
      return;
    }

    const numberPersons = getExamTrainingPersons(defaultContext);
    const selectedPriceID = getExamTrainingValues(defaultContext);
    const selectedPrice = examTrainingsPrices[selectedPriceID]?.price;

    setCurrentPrice(selectedPrice || 0);
    setSelectedPersons(numberPersons ? +numberPersons : 0);
  }, [defaultContext.howPersonA1B1, defaultContext.howPersonB2C2]);

  return (
    <ExamTrainingContext.Provider
      value={{
        defaultContext,
        setDefaultContext,
      }}
    >
      <Container className={styles.root} id="PrivateClasses">
        <Row>
          <Col lg="8">
            <ExamTrainingForm />
          </Col>
          <Col>
            <div className={styles.stickyWrapper}>
              <h6 className={styles.detailBoxLabel}>
                {translations[language].registeringFor}
              </h6>
              <AvailableCourse
                isOnPrivateClasses
                isOnExamTraining
                examTrainingPersons={selectedPersons}
                isAvailable={false}
                id="879875VN"
                name={translations[language].examTraining}
                category={{ value: translations[language].generalCourse }}
                categoryTooltipText={translations[language].generalCourse}
                courseStart={{ value: defaultContext.startDate }}
                courseEnd={{ value: defaultContext.endDate }}
                registrationDateStart={{ value: "20220211" }}
                location={{ value: translations[language].personLcL }}
                locationAddress={{
                  value:
                    defaultContext.coursePlace ===
                    translations[language].atYourCompany
                      ? "Wollekweg 22, 1220, Vienna, Austria"
                      : "",
                }}
                timetable={{ value: renderTimetable(defaultContext) }}
                regularPrice={currentPrice.toString()}
                salePrice={currentPrice.toString()}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </ExamTrainingContext.Provider>
  );
};

export default ExamTraining;
