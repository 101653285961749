import React, { useContext, useEffect, useRef, useState } from "react";
import { ExamTrainingContext } from "../../ExamTraining/ExamTraining";
import { PrivateClassContext } from "../../PrivateClasses/PrivateClasses";

import styles from "../ModalForms.module.css";

const CheckboxForms: React.FC<any> = ({
  customType = "private-class",
  form: { setFieldValue, values },
  label,
  value,
  field,
  id,
  isRadio,
  disabled,
}) => {
  const { setDefaultContext } = useContext(PrivateClassContext);
  const { setDefaultContext: setDefaultContextExamTraining } =
    useContext(ExamTrainingContext);
  const checkboxFormsInput = useRef(null);
  const checkboxFormsButton = useRef(null);
  const [inputWidth, setInputWidth] = useState(0);

  const contextSetter =
    customType === "private-class"
      ? setDefaultContext
      : setDefaultContextExamTraining;

  useEffect(() => {
    if (!checkboxFormsButton.current) return;

    // @ts-expect-error
    setInputWidth(checkboxFormsButton.current.offsetWidth);
  }, [checkboxFormsButton, values?.[field.name]?.includes(label)]);

  const setMultipleValues = () => {
    if (values[field.name].includes(label)) {
      const newValue = values[field.name].filter(
        (elem: string) => elem !== label
      );

      setFieldValue(field.name, newValue);
      contextSetter((prevState: any) => ({
        ...prevState,
        [field.name]: newValue,
      }));
      return;
    }

    setFieldValue(field.name, [...values[field.name], label]);
    contextSetter((prevState: any) => ({
      ...prevState,
      [field.name]: [...values[field.name], label],
    }));
  };

  const setSingleValues = () => {
    if (customType === "your-needs") {
      setFieldValue(field.name, label);
      return;
    }

    setFieldValue(field.name, label);
    contextSetter((prevState: any) => ({
      ...prevState,
      [field.name]: label,
    }));
  };

  return (
    <label
      htmlFor={label + id}
      className={styles.rootRadioForms}
      style={{ width: inputWidth }}
    >
      <input
        className={styles.radioFormsInput}
        ref={checkboxFormsInput}
        id={label + id}
        checked={values[field.name]?.includes(label)}
        type="checkbox"
        name={id}
        value={value}
        onChange={isRadio ? setSingleValues : setMultipleValues}
        disabled={disabled}
      />
      <span ref={checkboxFormsButton} className={styles.radioFormsButton}>
        {label}
        {!isRadio && values[field.name]?.includes(label) && (
          <span className={styles.radioFormsButtonClose}>x</span>
        )}
      </span>
    </label>
  );
};

export default CheckboxForms;
