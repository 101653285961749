import { useContext } from "react";
import LanguageContext from "../../LanguageContext";
import { translations } from "../translations";

export const WHAT_LEARN = () => {
  const { language } = useContext(LanguageContext);

  return translations[language].whatLearnOptions;
};

export const TEACHING_SYSTEM = () => {
  const { language } = useContext(LanguageContext);

  return [
    translations[language].languageCourses,
    translations[language].languageWorkshops,
  ];
};

export const COURSE_PLACE = () => {
  const { language } = useContext(LanguageContext);

  return [
    translations[language].online,
    translations[language].onsite,
    translations[language].inLcl,
  ];
};

export const COURSE_PLACE_FOR_BUSINESS = () => {
  const { language } = useContext(LanguageContext);

  return [
    translations[language].online,
    translations[language].atYourCompany,
    translations[language].inLcl,
  ];
};
