import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import AvailableCourse from "../AvailableCourses/AvailableCourse";
import LanguageContext from "../LanguageContext";
import { translations } from "../ModalForms/translations";
import PrivateClassesForm from "../PrivateClassesForm/PrivateClassesForm";

import { renderTimetable, UNIT_VALUE_CHANGER } from "./PrivateClassesHelpers";

import styles from "./PrivateClasses.module.css";

export const PrivateClassContext = createContext<any>({});

const PrivateClasses = () => {
  const [normalUnitValue, setNormalUnitValue] = useState(0);
  const [saleUnitValue, setSaleUnitValue] = useState(0);

  const { language } = useContext(LanguageContext);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [defaultContext, setDefaultContext] = useState({
    whichLanguageYouWant: [],
    whatLearn: [],
    yourCurrentLevel: "",
    coursePlace: "",
    howOften: "1",
    howOftenDays: [],
    howOftenHours: "",
    preferedNumber: "5",
    startDate: "",
    endDate: "",
  });

  const fetchPrices = useCallback(async () => {
    const { upTo5Price, upTo20Price } = await fetch(
      "/api/getPrivateClassesPrices?language=" + language
    ).then((respond) => respond.json());

    setNormalUnitValue(upTo5Price?.price);
    setSaleUnitValue(upTo20Price?.price);
  }, []);

  useEffect(() => {
    fetchPrices();
  }, [fetchPrices]);

  const calculateValue = () => {
    const unitValue =
      Number(defaultContext.preferedNumber) > UNIT_VALUE_CHANGER
        ? saleUnitValue
        : normalUnitValue;

    return Number(defaultContext.preferedNumber) * unitValue;
  };

  useEffect(() => {
    const calculatedPrice = calculateValue();
    setCurrentPrice(calculatedPrice);
  }, [normalUnitValue, saleUnitValue, defaultContext.preferedNumber]);

  return (
    <PrivateClassContext.Provider
      value={{
        defaultContext,
        setDefaultContext,
      }}
    >
      <Container className={styles.root} id="PrivateClasses">
        <Row>
          <Col lg="8">
            <PrivateClassesForm />
          </Col>
          <Col>
            <div className={styles.stickyWrapper}>
              <h6 className={styles.detailBoxLabel}>
                {translations[language].registeringFor}
              </h6>
              <AvailableCourse
                isOnPrivateClasses
                isAvailable={false}
                id="879875VN"
                name={translations[language].privateClasses}
                category={{ value: translations[language].generalCourse }}
                categoryTooltipText={translations[language].generalCourse}
                courseStart={{ value: defaultContext.startDate }}
                courseEnd={{ value: defaultContext.endDate }}
                registrationDateStart={{ value: "" }}
                location={{ value: translations[language].personLcL }}
                locationAddress={{
                  value:
                    defaultContext.coursePlace ===
                    translations[language].atYourCompany
                      ? "Wollekweg 22, 1220, Vienna, Austria"
                      : "",
                }}
                timetable={{ value: renderTimetable(defaultContext) }}
                regularPrice={currentPrice.toString()}
                salePrice={currentPrice.toString()}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateClassContext.Provider>
  );
};

export default PrivateClasses;
