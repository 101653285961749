import { useState, useContext, useCallback } from "react";
import LanguageContext from "../LanguageContext";
import { translations } from "../OrderStore/translations";

export const UNIT_VALUE_CHANGER = 5;

export const PRIVATE_CLASSES_TO_5_ID = 6087;
export const PRIVATE_CLASSES_TO_20_ID = 6101;

export const EXAM_TRAINING_COURSES = {
  A1B1: {
    1: 12933,
    2: 7642,
  },
  B2C2: {
    1: 7643,
    2: 12934,
  },
};

export const removeEmptyElements = (array: any) =>
  array.filter((elem: any) => elem);

export const generateNotes = (data: any) => {
  // @ts-expect-error
  const reducedDataArray = data.map(([title, value]) => {
    if (typeof value === "string" || typeof value === "number") {
      return `${title}:${value}`;
    }
    if (Array.isArray(value)) {
      return `${title}:${value.join(",\r\n")}`;
    }
  });
  return `${reducedDataArray.join(",\r\n")}`;
};

export const getExamTrainingPersons = (data: any) => {
  const { howPersonA1B1, howPersonB2C2 } = data;
  const responseForm =
    !!howPersonA1B1 && howPersonA1B1.length > 0
      ? ["A1B1", howPersonA1B1[0] ? +howPersonA1B1[0].split(" ")[0] : null]
      : ["B2C2", howPersonB2C2[0] ? +howPersonB2C2[0].split(" ")[0] : null];

  return responseForm[1];
};

export const getExamTrainingValues = (data: any) => {
  const { howPersonA1B1, howPersonB2C2 } = data;
  const responseForm =
    !!howPersonA1B1 && howPersonA1B1.length > 0
      ? ["A1B1", howPersonA1B1[0] ? +howPersonA1B1[0].split(" ")[0] : null]
      : ["B2C2", howPersonB2C2[0] ? +howPersonB2C2[0].split(" ")[0] : null];

  const examTraining =
    responseForm.length > 0 &&
    // @ts-expect-error
    EXAM_TRAINING_COURSES[responseForm[0]][responseForm[1]];

  return examTraining;
};

export const generateNotesToUrl = (
  data: any,
  type: string = "privateClasses"
) => {
  const dataArray = Object.entries(data);
  const notes = encodeURIComponent(JSON.stringify(dataArray));

  if (type === "examTraining") {
    const id = getExamTrainingValues(data);

    return {
      query: `notes='${notes}'&quantity=1`,
      product_id: id,
    };
  }

  let quantity = 0;
  dataArray.forEach(([title, value]) => {
    if (title !== "preferedNumber") return;

    // @ts-expect-error
    return (quantity = value ? value : 1);
  });

  return {
    query: `notes='${notes}'&quantity=${quantity}`,
    product_id:
      quantity > 5 ? PRIVATE_CLASSES_TO_20_ID : PRIVATE_CLASSES_TO_5_ID,
  };
};

export const renderSelectedDays = (defaultContext: any) => {
  const { howOftenDays = [] } = defaultContext;
  if (howOftenDays.length === 0) return;

  return howOftenDays.join(", ");
};

export const renderTimetable = (defaultContext: any) => {
  const { language } = useContext(LanguageContext);
  let endLabel = "";

  const selectedHours =
    defaultContext.howOftenHours && `(${defaultContext.howOftenHours})`;

  switch (true) {
    case selectedHours.includes("08:00-12:00"):
      endLabel = translations[language].timesAWeekEnd[0];
      break;
    case selectedHours.includes("12:00-16:00"):
      endLabel = translations[language].timesAWeekEnd[1];
      break;
    case selectedHours.includes("16:00-20:00"):
      endLabel = translations[language].timesAWeekEnd[2];
      break;
  }

  const selectedDays = renderSelectedDays(defaultContext) || "";
  const selectedWeekTimes =
    defaultContext.howOften &&
    `/ ${defaultContext.howOften} ${translations[language].timesAWeek} ${endLabel}`;

  return `${selectedDays} ${selectedWeekTimes} ${selectedHours}`;
};
