import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    name: "First Name:",
    namePlaceholder: "e.g. John",
    surname: "Surname:",
    surnamePlaceholder: "e.g. Smith",
    email: "E-mail address:",
    emailPlaceholder: "e.g. john.smith@domain.com",
    phone: "Phone number:",
    phonePlaceholder: "e.g. 0660 000 00 00",
    contactMethod: "Preferred contact method:",
    contactMethodMail: "Send me an e-mail",
    contactMethodCall: "Call me",
    contactMethodBoth: "Both",
    contactHours: "Preferred contact hours:",
    allDay: "all day",
    subject: "Subject",
    message: "Your message:",
    messagePlaceholder:
      "Share your questions or comments with us, as well as your preferred times and dates for the course",
    cv: "Your CV:",
    languageInterested: "Which language do you want to learn?",
    applyAny: "What do you want to focus on?",
    speaking: "Speaking",
    writing: "Writing",
    grammar: "Grammar",
    examPreparation: "Exam preparation",
    levelQuickly: "To complete the level quickly",
    learnInGroup: "Do you want to learn in a group?",
    currentLevel: "Desired course level",
    currentLevelB2B: "Desired course level:",
    yourrAvailability: "Select preferred course dates",
    operatingMode: "Which of the following do you want to take?",
    teachingSystem: "Which of the following do you want to take?",
    courseParticipation: "Number of course participants",
    coursePlace: "Where should the course take place?",
    howOften: "How often should lessons take place?",
    whatLearn: "What do you (or your group) want to learn?",
    howMuchLessons: "Preferred number of learning units (1 unit = 60 minutes)",
    whatYouLearn: "Which of these apply to you?",
    whatLearnOptions: [
      "The entire level e.g. A1 should be covered thoroughly",
      "I want to practice these skills: - reading, -writing, -listening, -speaking",
      "I want to prepare for an exam",
      "I want to extend my vocabulary and improve my speaking skills",
      "I want to improve my grammar and orthography",
      "I want to learn language for a specific professional context (e.g. medical language)",
      "I want to learn to communicate with deaf people",
      "I want to participate in a language workshop",
      "I want to improve my language skills in general",
    ],
    preferedUnits: "Number of units (1 unit = 60 minutes)",
    placementTest: "Should we organize a placement test for you?",
    contactData: "Contact data",
    english: "English",
    german: "German",
    french: "French",
    spanish: "Spanish",
    latin: "Latin",
    austrian: "Austrian sign language",
    other: "Other",
    regulationsRule:
      "I hereby confirm the correctness of my personal data in the contact form. I hereby accept the <a href='/en/terms-conditions-language-courses-all-levels-exam-preparation-lcl-language-institute-vienna'>General Terms and Conditions (GTC)</a> of the LcL Institute.*",
    privatePolicyRule:
      "I have read and understood the <a href='/en/privacy-policy-and-references-from-language-communication-laboratory-institute-in-vienna'>privacy policy</a>.*",
    newsletterRule:
      "I would like to receive your newsletter for free to the provided e-mail address.",
    yes: "Yes",
    no: "No",
    findButton: "Find the right course for you!",
    offerButton: "Request a course offer",
    sendButton: "Send message",
    findCourseHeading: "Find the right course for you!",
    findCourseSubheading:
      "Fill in our questionnaire, to see which course meets your needs.",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    fridayWeekend: "Friday / the weekend",
    online: "Remotely (on-line)",
    onsite: "In Vienna (on-site)",
    inLcl: "At the LcL Institute",
    atYourCompany: "At your company",
    personLcL: "In person in LcL Institute",
    verifyFreeAccessSubject: [
      "I have questions about LcL's courses.",
      "I have a question about registration.",
      "I want to do a placement test.",
      "I don't know which language course is right for me.",
      "I need a confirmation.",
      "I have a different question.",
    ],
    verifyFreeAccessSubjectCV: [
      "I want to introduce myself to the language team.",
      "I have a question about my qualifications.",
      "I want to observe a lesson/lessons at LcL.",
      "I have more questions about the LcL Institute",
    ],
    privateClassesLernTopics: [
      "I want to quickly reach a certain level",
      "I'm preparing for an exam",
      "I want to communicate confidently",
      "I want to improve my writing skills",
      "I want to improve my grammar",
    ],
    uploadFile: "Upload file",
    topicsContact: ["I want to verify my current language level"],
    estimateB2BTitle: "Receive a B2B cost estimate",
    estimateB2BSubtitle:
      "Fill out this form we'll get back to you within 3 days with a suitable offer.",
    languageCourses: "Language courses",
    languageWorkshops: "Language workshops",
    chooseDays: "Select preferred course dates:",
    chooseHours: "Choose the hours you prefer:",
    startDate: "Start date",
    privateClassesTitle: "Tell us more about your wishes:",
    yourNeedsTitle: "Tell us more about your wishes:",
    notKnowYourLevel:
      "Not sure what your current level is? We help you figure it out!",
    verifyYourLevel: "Contact us",
    unitsValidateInfo:
      "The minimum amount in private classes is 6 learning hours, maximum is 20.",
    youNeedMore: "You need more?",
    contactUs: "Contact us",
    tailoredLearning:
      "to receive a tailored learning program to reach your goal efficiently!",
    registeringFor: "Your are registering for:",
    any: "Any",
    generalCourse: "General course",
    privateClasses: "Private classes",
    examTraining: "Exam training",
    latestFourteenDays:
      "I book this course at short notice and hereby waive my right of withdrawal.*",
    selectedLanguageTestsLabel:
      "I am preparing for the following language test",
    examTrainingLearnTopics: [
      "Above all, I want to practice reading",
      "Above all, I want to practice listening",
      "Above all, I want to practice writing",
      "Above all, I want to practice speaking",
      "I would like to train all examination modules equally",
      "I also want the integrative values and practice orientation knowledge",
    ],
    examTrainingTitle: "Tell uns more about your wishes:",
    examTrainingReserveLanguage: "Which language do you want to learn?",
    examTrainingYourCurrentLevel: "What's your current level?",
    examTrainingModuleExam:
      "Welche Prüfungsmodule möchten Sie vorwiegend trainieren?",
    examTrainingExamPlace: "Where should your course take place?",
    examTrainingHowMuchInHours: "How often should lessons take place?",
    examTrainingPrefferDays: "Select preferred course dates:",
    examTrainingPrefferHours: "Choose the hours you prefer:",
    examTrainingStardAndEndDate: "Start date:",
    person1: "1 Person",
    person2: "2 People",
    std: "hrs",
  },
  [Languages.German]: {
    name: "Vorname:",
    namePlaceholder: "z.B. Max",
    surname: "Nachname:",
    surnamePlaceholder: "z.B. Mustermann",
    email: "E-Mail-Adresse:",
    emailPlaceholder: "max.mustermann@domain.at",
    phone: "Telefonnummer:",
    phonePlaceholder: "z.B. +43 660 000 00 00",
    contactMethod: "Bevorzugte Kontaktmethode:",
    contactMethodMail: "Senden Sie mir eine E-Mail",
    contactMethodCall: "Rufen Sie mich an",
    contactMethodBoth: "Beide Kontaktmöglichkeiten",
    contactHours: "Bevorzugte Kontaktzeit:",
    allDay: "ganztägig",
    subject: "Thema",
    message: "Ihre Nachricht:",
    messagePlaceholder:
      "Teilen Sie uns bitte Ihre Anliegen und Fragen, die bevorzugten Kurstage - und zeiträume z.B. 16:00-20:00 mit",
    cv: "Ihr Lebenslauf:",
    languageInterested: "Welche Sprache möchten Sie lernen?",
    applyAny: "Worauf möchten Sie sich konzentrieren?",
    speaking: "Sprechen",
    writing: "Schreiben",
    grammar: "Grammatik",
    examPreparation: "Prüfungsvorbereitung",
    levelQuickly: "Ein Niveau schnell erreichen",
    learnInGroup: "Möchten Sie den Sprachunterricht in einer Gruppe?",
    currentLevel: "Gewünschtes Kursniveau",
    currentLevelB2B: "Gewünschtes Kursniveau:",
    yourrAvailability: "Wählen Sie die bevorzugten Kurstage aus",
    operatingMode: "Wo soll der Unterricht stattfinden?",
    teachingSystem:
      "Wählen Sie Zutreffendes aus Sprachkurs (welche Sprache möchten Sie lernen?)",
    courseParticipation: "Anzahl an Teilnehmenden",
    coursePlace: "Wo wollen Sie den Sprachunterricht wahrnehmen?",
    howOften: "Wie oft pro Woche können Sie am Sprachunterricht teilnehmen?",
    whatLearn: "Was wollen Sie oder Ihre Gruppe lernen?",
    howMuchLessons:
      "Gewünschte Anzahl der Unterrichtseinheiten (1 Einheit = 60 Minuten)",
    whatYouLearn: "Was möchten Sie lernen?",
    whatLearnOptions: [
      "Ich möchte ein gesamtes Sprachiveau, abschließen, z.B. A1.2",
      "Ich möchte folgende Sprachmodule üben: - Lesen, - Schreiben, - Hören, - Sprechen",
      "Ich möchte mich auf eine Sprachprüfung vorbereiten",
      "Ich möchte meinen Wortschatz erweitern und dabei das Sprechen verbessern",
      "Ich möchte grammatische und orthografische Lücken schließen",
      "Ich möchte eine bestimmte Berufssprache erlernen, z.B. Mediziner-Sprache",
      "Ich möchte lernen, mit Gehörlosen zu kommunizieren",
      "Ich möchte einen Sprachworkshop in Anspruch nehmen",
      "Ich möchte ohne spezielle Wünsche meine Sprachkenntnisse verbessern",
    ],
    preferedUnits:
      "Anzahl der gewünschten Unterrichteinheiten (1 Einheit = 60 Minuten)",
    placementTest: "Sollen wir Ihr derzeitiges Spachniveau einstufen?",
    contactData: "Kontaktdaten",
    english: "Englisch",
    german: "Deutsch",
    french: "Französisch",
    spanish: "Spanisch",
    latin: "Latein",
    austrian: "Österreichische Gebärdensprache",
    other: "Andere",
    regulationsRule:
      "Ich bestätige hiermit die Richtigkeit meiner personenbezogenen Daten im Kontaktformular. Ich akzeptiere hiermit die allgemeinen <a href='/allgemeine-geschaeftsbedingungen-sprachschule-wien-deutsch-fremdsprachen'>Geschäftsbedingungen (AGB)</a> des LcL-Instituts.*",
    privatePolicyRule:
      "Ich habe die <a href='/datenschutzerklaerung-quellennachweis-sprachkurse-sprachworkshops'>Datenschutzerklärung</a> gelesen und verstanden.*",
    newsletterRule:
      "Ich möchte den LcL-Newsletter gratis per E-Mail abonnieren.",
    yes: "Ja",
    no: "Nein",
    findButton: "Finden Sie Ihren Sprachkurs!",
    offerButton: "Angebot holen",
    sendButton: "Nachricht senden",
    findCourseHeading: "Sie suchen – wir finden!",
    findCourseSubheading:
      "Beantworten Sie den Fragebogen und schauen Sie, welcher Sprachunterricht zu Ihnen passt.",
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag ",
    saturday: "Samstag",
    sunday: "Sonntag",
    fridayWeekend: "Freitag / Wochenende",
    online: "Online",
    onsite: "Bei mir zu Hause",
    inLcl: "Am LcL-Institut",
    atYourCompany: "In Ihrem Unternehmen",
    personLcL: "Persönlich am LcL Institut",
    verifyFreeAccessSubject: [
      "Ich habe noch Fragen zum LcL-Kursangebot.",
      "Ich habe eine Frage zur Anmeldung.",
      "Ich möchte einen Einstufungstest machen.",
      "Ich weiß nicht, welcher Sprachkurs für mich richtig ist.",
      "Ich brauche eine Bestätigung.",
      "Ich habe eine andere Frage.",
    ],
    verifyFreeAccessSubjectCV: [
      "Ich möchte mich als Lehrkraft vorstellen.",
      "Ich habe Fragen zu meiner Qualifikation.",
      "Ich möchte bei Ihrem Sprachunterricht hospitieren.",
      "Ich habe noch weitere Fragen zum LcL-Institut.",
    ],
    privateClassesLernTopics: [
      "Ich möchte schnell ein bestimmtes Sprachniveau erreichen",
      "Ich bereite mich auf eine Sprachprüfung vor",
      "Ich möchte problemlos in der gewünschten Sprache kommunizieren",
      "Ich möchte fehlerlos schreiben",
      "Ich möchte meine Grammatik verbessern",
    ],
    uploadFile: "Datein hochladen",
    topicsContact: ["Ich möchte einen Einstufungstest ausmachen"],
    estimateB2BTitle: "Holen Sie sich einen Kostenvoranschlag",
    estimateB2BSubtitle:
      "Füllen Sie das Formular aus und wir lassen Ihnen binnen 3 Tagen ein passendes Angebot zukommen.",
    languageCourses: "Sprachkurse",
    languageWorkshops: "Sprachworkshops",
    chooseDays: "Wählen Sie die bevorzugten Kurstage aus:",
    chooseHours: "Wählen Sie den bevorzugten Kurszeitraum aus:",
    startDate: "Kursbeginn",
    privateClassesTitle:
      "Teilen Sie uns Ihre Wünsche für den Privatunterricht mit:",
    yourNeedsTitle: "Teilen Sie uns Ihre Wünsche für das Firmenangebot mit:",
    notKnowYourLevel:
      "Sie wissen nocht nicht, welches Sprachlevel Sie haben? Kein Problem – wir stufen Sie gern ein!",
    verifyYourLevel: "Kontakt aufnehmen",
    unitsValidateInfo:
      "Die Buchung einzelner Einheiten ist für zwischen 5 und 20 Stunden möglich.",
    youNeedMore: "Benötigen Sie mehr Unterricht?",
    contactUs: "Kontaktieren Sie uns",
    tailoredLearning:
      " und Sie bekommen einen auf Sie zugeschnittenen Lehrplan, der Ihnen beim Erreichen Ihrer Lernziele hilft.",
    registeringFor: "Sie melden sich für Ihren gewünschten Kurs an:",
    any: "Jederzeit",
    generalCourse: "Allgemeiner Kurs",
    privateClasses: "Privatunterricht",
    examTraining: "Prüfungstraining",
    latestFourteenDays:
      "Ich buche diesen Kurs kurzfristig und verzichte hiermit auf mein Widerrufsrecht.*",
    selectedLanguageTestsLabel:
      "Ich bereite mich auf folgende Sprachprüfung vor",
    examTrainingLearnTopics: [
      "Ich möchte vor allem das Lesen traineren",
      "Ich möchte vor allem das Hören traineren",
      "Ich möchte vor allem das Schreiben traineren",
      "Ich möchte vor allem das Sprechen traineren",
      "Ich möchte alle Prüfungsmodule gleichermaßen traineren",
      "Ich möchte auch das integrative Werte und Orientierungswisssen üben",
    ],
    examTrainingTitle:
      "Teilen Sie uns Ihre Wünsche für das Prüfungstraining mit:",
    examTrainingReserveLanguage: "Ich buche ein Prüfungstraining für:",
    examTrainingYourCurrentLevel:
      "Für welches Sprachniveau ist die Prüfungsvorbereitung?",
    examTrainingModuleExam:
      "Welche Prüfungsmodule möchten Sie vorwiegend trainieren?",
    examTrainingExamPlace: "Wo wollen Sie das Prüfungstraining machen?",
    examTrainingHowMuchInHours:
      "Wie oft pro Woche können Sie am Prüfungstraining teilnehmen? (in Std.)",
    examTrainingPrefferDays: "Wählen Sie die bevorzugten Trainingstage aus:",
    examTrainingPrefferHours:
      "Wählen Sie den bevorzugten Trainingszeitraum aus:",
    examTrainingStardAndEndDate: "Trainingsbeginn und Trainingsende:",
    person1: "1 Person",
    person2: "2 Personen",
    std: "std.",
  },
};

export { translations };
