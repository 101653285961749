import React, { useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormik, Field, FormikProvider } from "formik";
import {
  CURRENT_LEVEL,
  LANGUAGES,
  YOUR_AVAILABILITY,
} from "../ModalForms/FindCourseForm/FindCourseFormData";
import { COURSE_PLACE } from "../ModalForms/EstimateB2BForm/EstimateB2BFormData";
import CheckboxForms from "../ModalForms/CheckboxForms/CheckboxForms";
import LanguageContext from "../LanguageContext";
import cx from "classnames";
import styles from "../ModalForms/ModalForms.module.css";
import Link from "next/link";
import PlusMinutInput from "../ModalForms/PlusMinusForms/PlusMinutInput";
import { translations } from "../ModalForms/translations";
import { ExamTrainingContext } from "./ExamTraining";
import {
  EXAM_TRAINING_DAYS,
  LANGUAGES_EXAM,
  SELECTED_LANGUAGE_DETAILS,
  WHAT_WANT_LEARN_EXAM,
} from "./ExamTrainingFormData";
import CheckboxWithInput from "../ModalForms/CheckboxWithInput/CheckboxWithInput";
import CheckboxWithInputWithoutStyles from "../ModalForms/CheckboxWithInput/CheckboxWithInputWithoutStyles";
import { isArray } from "util";

const ExamTrainingForm = () => {
  const { language } = useContext(LanguageContext);
  const { defaultContext, setDefaultContext } = useContext(ExamTrainingContext);

  const [requestStatus, setRequestStatus] = React.useState<{
    status: string;
    message: string;
  } | null>(null);

  const formik = useFormik({
    initialValues: {
      whichLanguageYouWant: "",
      whatLearn: "",
      yourCurrentLevel: "",
      coursePlace: "",
      howOften: "1",
      howOftenDays: "",
      howOftenHours: "",
      howPersonA1B1: "",
      howPersonB2C2: "",
      startDate: "",
      endDate: "",
    },
    enableReinitialize: true,
    onSubmit: async (data, actions) => {
      try {
        actions.setSubmitting(true);
        actions.resetForm();
        actions.setSubmitting(false);
      } catch (e) {
        setRequestStatus({
          status: "failed",
          message: "error",
        });
      }
    },
  });

  const multipleUpdateContext = (value: any, name: string) => {
    if (defaultContext[name]?.includes(value.target.value)) {
      setDefaultContext((prevState: any) => ({
        ...prevState,
        [name]: [...prevState[name]].filter((e) => e !== value.target.value),
      }));

      return;
    }

    switch (true) {
      case typeof defaultContext?.[name] === "string":
        setDefaultContext((prevState: any) => ({
          ...prevState,
          [name]: [prevState[name], value.target.value],
        }));

        break;
      case Array.isArray(defaultContext?.[name]) &&
        defaultContext?.[name].length >= 1:
        setDefaultContext((prevState: any) => ({
          ...prevState,
          [name]: [...prevState[name], value.target.value],
        }));

        break;
      default:
        setDefaultContext((prevState: any) => ({
          ...prevState,
          [name]: value.target.value,
        }));
    }
  };

  const contextUpdate = (
    value: any,
    name: string,
    isMultiple: boolean = false
  ) => {
    formik.handleChange(value);

    if (name === "whatLearn" || isMultiple) {
      multipleUpdateContext(value, name);
      return;
    }

    setDefaultContext((prevState: any) => ({
      ...prevState,
      [name]: value.target.value,
    }));
  };

  return (
    <FormikProvider value={formik}>
      <div className={styles.estimateTitleWrapper}>
        <h2 className={styles.modalTitle}>
          {translations[language].examTrainingTitle}
        </h2>
      </div>

      <Form onSubmit={formik.handleSubmit}>
        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.bigFormLabel}>
            1. {translations[language].examTrainingReserveLanguage}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            {LANGUAGES_EXAM().map((place) => (
              <Field
                name="whichLanguageYouWant"
                id="check"
                label={place}
                value={place}
                customType="exam-training"
                component={CheckboxForms}
              />
            ))}
            <Field
              name="whichLanguageYouWant"
              id="whichLanguageYouWant"
              label={translations[language].other}
              value={translations[language].other}
              customType="exam-training"
              component={CheckboxWithInput}
            />
          </div>
        </Form.Group>

        {formik?.values?.whichLanguageYouWant &&
          [...formik.values.whichLanguageYouWant].map(
            (selectedLanguage) =>
              SELECTED_LANGUAGE_DETAILS()?.[selectedLanguage] && (
                <Form.Group>
                  <Form.Label className={styles.bigFormLabel}>
                    {translations[language].selectedLanguageTestsLabel}:
                  </Form.Label>
                  <div
                    className={cx(
                      styles.estimateCheckboxWrapper,
                      styles.estimateCheckboxWrapperFullHeight
                    )}
                  >
                    {SELECTED_LANGUAGE_DETAILS()?.[selectedLanguage]?.map(
                      (checkbox) => (
                        <label>
                          <Field
                            type="checkbox"
                            name={`selectedTestFor${selectedLanguage}`}
                            value={checkbox}
                            onChange={(e: any) =>
                              contextUpdate(
                                e,
                                `selectedTestFor${selectedLanguage}`,
                                true
                              )
                            }
                          />
                          {checkbox}
                        </label>
                      )
                    )}
                    <Field
                      name={`selectedTestFor${selectedLanguage}`}
                      id={`selectedTestFor${selectedLanguage}`}
                      label={translations[language].other}
                      value={translations[language].other}
                      customType="exam-training"
                      component={CheckboxWithInputWithoutStyles}
                    />
                  </div>
                </Form.Group>
              )
          )}

        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.bigFormLabel}>
            2. {translations[language].examTrainingYourCurrentLevel}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            {CURRENT_LEVEL.map((system) => (
              <Field
                name="yourCurrentLevel"
                id="check"
                label={system}
                value={system}
                isRadio
                customType="exam-training"
                component={CheckboxForms}
              />
            ))}
          </div>
          <p className={styles.additionalInfoGroup}>
            {translations[language].notKnowYourLevel}{" "}
            <Link href="#verify_level">
              {translations[language].verifyYourLevel}
            </Link>
          </p>
        </Form.Group>

        <Form.Group>
          <Form.Label className={styles.bigFormLabel}>
            3. {translations[language].examTrainingModuleExam}
          </Form.Label>
          <div className={styles.estimateCheckboxWrapper}>
            {WHAT_WANT_LEARN_EXAM().map((checkbox) => (
              <label>
                <Field
                  type="checkbox"
                  name="whatLearn"
                  value={checkbox}
                  onChange={(e: any) => contextUpdate(e, "whatLearn")}
                />
                {checkbox}
              </label>
            ))}
          </div>
        </Form.Group>

        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.bigFormLabel}>
            4. {translations[language].examTrainingExamPlace}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            {COURSE_PLACE().map((system) => (
              <Field
                name="coursePlace"
                id="check"
                label={system}
                value={system}
                isRadio
                customType="exam-training"
                component={CheckboxForms}
              />
            ))}
          </div>
        </Form.Group>

        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.bigFormLabel}>
            5. {translations[language].examTrainingHowMuchInHours}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            <Field
              name="howOften"
              id="check"
              label={formik.values.howOften}
              value={formik.values.howOften}
              customType="exam-training"
              component={PlusMinutInput}
            />
          </div>
        </Form.Group>

        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.smallFormLabel}>
            {translations[language].examTrainingPrefferDays}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            {EXAM_TRAINING_DAYS().map((place) => (
              <Field
                name="howOftenDays"
                id="check"
                label={place}
                value={place}
                customType="exam-training"
                component={CheckboxForms}
              />
            ))}
          </div>

          <Form.Label className={styles.smallFormLabel}>
            {translations[language].examTrainingPrefferHours}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            {["08:00-12:00", "12:00-16:00", "16:00-20:00"].map((place) => (
              <Field
                name="howOftenHours"
                id="check"
                label={place}
                value={place}
                isRadio
                customType="exam-training"
                component={CheckboxForms}
              />
            ))}
          </div>
        </Form.Group>

        <div className={styles.examTrainingCourseSelectWrapper}>
          <Form.Group className={styles.estimateWrapper} controlId="formReason">
            <Form.Label className={styles.smallFormLabel}>
              A1-B1 (5 {translations[language].std})
            </Form.Label>
            <div className={styles.radiosWrapper}>
              {[
                translations[language].person1,
                translations[language].person2,
              ].map((person) => (
                <Field
                  name="howPersonA1B1"
                  id="howPersonA1B1"
                  label={person}
                  value={person}
                  disabled={
                    formik.values.howPersonB2C2.length > 0 ||
                    (formik.values.howPersonA1B1.length > 0 &&
                      formik.values.howPersonA1B1[0] !== person)
                  }
                  customType="exam-training"
                  component={CheckboxForms}
                />
              ))}
            </div>
          </Form.Group>
          <Form.Group className={styles.estimateWrapper} controlId="formReason">
            <Form.Label className={styles.smallFormLabel}>
              B2-C2 (7 {translations[language].std})
            </Form.Label>
            <div className={styles.radiosWrapper}>
              {[
                translations[language].person1,
                translations[language].person2,
              ].map((person) => (
                <Field
                  name="howPersonB2C2"
                  id="howPersonB2C2"
                  label={person}
                  value={person}
                  disabled={
                    formik.values.howPersonA1B1.length > 0 ||
                    (formik.values.howPersonB2C2.length > 0 &&
                      formik.values.howPersonB2C2[0] !== person)
                  }
                  customType="exam-training"
                  component={CheckboxForms}
                />
              ))}
            </div>
          </Form.Group>
        </div>

        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.bigFormLabel}>
            7. {translations[language].examTrainingStardAndEndDate}
          </Form.Label>
          <div className={styles.datesWrapper}>
            <Form.Control
              type="date"
              name="startDate"
              onChange={(e) => contextUpdate(e, "startDate")}
              value={formik.values.startDate}
            />
            -
            <Form.Control
              type="date"
              name="endDate"
              onChange={(e) => contextUpdate(e, "endDate")}
              value={formik.values.endDate}
            />
          </div>
        </Form.Group>
      </Form>
    </FormikProvider>
  );
};

export default ExamTrainingForm;
