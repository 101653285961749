import React, { useContext } from "react";
import { Img } from "../elements/Img";
import LanguageContext from "../LanguageContext";
import locationIcon from "./assets/locationIcon.svg";
import { translations } from "./translations";

import styles from "./AvailableCourses.module.css";

interface ISingleInformation {
  label: string;
  text: string;
  additionalText?: string;
}

const SingleInformation: React.FC<ISingleInformation> = ({
  label,
  text,
  additionalText,
}) => {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.singleInformationWrapper}>
      <p className={styles.informationLabel}>{label}</p>
      <div className={styles.informationTextWrapper}>
        <p className={styles.informationText}>{text}</p>
        {label === translations[language].location && (
          <Img src={locationIcon} />
        )}
      </div>
      {additionalText && (
        <p className={styles.informationAdditionalText}>{additionalText}</p>
      )}
    </div>
  );
};
export default SingleInformation;
