import React, { useContext, useEffect, useRef, useState } from "react";
import { ExamTrainingContext } from "../../ExamTraining/ExamTraining";
import { PrivateClassContext } from "../../PrivateClasses/PrivateClasses";

import styles from "../ModalForms.module.css";

const PlusMinutInput: React.FC<any> = ({
  customType = "private-class",
  form: { setFieldValue, values },
  label,
  value,
  field,
  id,
}) => {
  const { setDefaultContext } = useContext(PrivateClassContext);
  const { setDefaultContext: setDefaultContextExamTraining } =
    useContext(ExamTrainingContext);
  const plusMinusValueInput = useRef(null);

  const contextSetter =
    customType === "private-class"
      ? setDefaultContext
      : setDefaultContextExamTraining;

  const setSingleValues = () => {
    if (customType === "your-needs") {
      setFieldValue(field.name, label);
      return;
    }

    setFieldValue(field.name, label);
    contextSetter((prevState: any) => ({
      ...prevState,
      [field.name]: label,
    }));
  };

  const updateDown = (e: any) => {
    e.preventDefault();

    if (customType === "your-needs") {
      setFieldValue(field.name, +value - 1);
      return;
    }

    setFieldValue(field.name, +value - 1);
    contextSetter((prevState: any) => ({
      ...prevState,
      [field.name]: +value - 1,
    }));
  };

  const updateUp = (e: any) => {
    e.preventDefault();

    if (customType === "your-needs") {
      setFieldValue(field.name, +value + 1);
      return;
    }

    setFieldValue(field.name, +value + 1);
    contextSetter((prevState: any) => ({
      ...prevState,
      [field.name]: +value + 1,
    }));
  };

  return (
    <label htmlFor={label + id} className={styles.plusMinusWrapper}>
      <button disabled={+value === 0} onClick={(e) => updateDown(e)}>
        -
      </button>
      <input
        className={styles.rangeValueBox}
        ref={plusMinusValueInput}
        id={label + id}
        type="number"
        name={id}
        value={value}
        onChange={setSingleValues}
      />
      <button onClick={(e) => updateUp(e)}>+</button>
    </label>
  );
};

export default PlusMinutInput;
