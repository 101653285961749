import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Img } from "../elements/Img";
import Button from "../elements/Button";

import infoTooltipIcon from "../elements/EventCard/assets/infoTooltipIcon.svg";
import calendarIcon from "./assets/calendarIcon.svg";
import locationIcon from "./assets/locationIcon.svg";

import styles from "./AvailableCourses.module.css";
import SingleInformation from "./SingleInformation";
import SinglePrice from "./SinglePrice";
import { useRouter } from "next/router";
import { translations } from "./translations";
import LanguageContext from "../LanguageContext";
import { PrivateClassContext } from "../PrivateClasses/PrivateClasses";
import { generateNotesToUrl } from "../PrivateClasses/PrivateClassesHelpers";

import cx from "classnames";
import { REBATE_TYPES } from "../OrderStore/utils/RebateTypes";
import { dateFormater } from "../OrderStore/utils/helpers";
import { ExamTrainingContext } from "../ExamTraining/ExamTraining";
import StockAvailable from "./StockAvailable";

interface StringElement {
  value?: string;
}

export interface IAvailableCourse {
  id: string;
  createDate?: string;
  isOnOrder?: boolean;
  withoutButton?: boolean;
  isOnPrivateClasses?: boolean;
  isOnExamTraining?: boolean;
  examTrainingPersons?: number;
  isAvailable?: boolean;
  isOnCoursesList?: boolean;
  name: string;
  isThankYouPage?: boolean;
  category: StringElement;
  categoryTooltipText?: string;
  courseStart: StringElement;
  courseEnd: StringElement;
  registrationDateStart?: StringElement;
  location: StringElement;
  locationAddress: StringElement;
  timetable: StringElement;
  timetableAdditionalText?: string;
  regularPrice: string;
  salePrice?: string;
  salePriceLabel?: StringElement;
  salePriceStartDate?: string;
  salePriceExpiredDate?: string;
  couponRebate?: { value: number; type: string };
  stockQuantity?: number;
}

const AvailableCourse: React.FC<IAvailableCourse> = ({
  id,
  isOnOrder = false,
  withoutButton = false,
  isOnPrivateClasses = false,
  isOnExamTraining = false,
  examTrainingPersons,
  isOnCoursesList = false,
  isAvailable = true,
  name: title,
  isThankYouPage = false,
  category: { value: category },
  categoryTooltipText,
  courseStart: { value: startDate },
  courseEnd: { value: endDate },
  // @ts-expect-error
  registrationDateStart: { value: registrationDate },
  location: { value: location },
  locationAddress: { value: locationAdditionalText },
  timetable: { value: timetable },
  regularPrice: normalPrice,
  salePrice: discountPrice,
  salePriceLabel,
  salePriceStartDate,
  salePriceExpiredDate,
  couponRebate,
  stockQuantity,
}) => {
  const { defaultContext: defaultPrivateClassContext } =
    useContext(PrivateClassContext);
  const { defaultContext: defaultExamTrainingContext } =
    useContext(ExamTrainingContext);
  const { language } = useContext(LanguageContext);
  const router = useRouter();

  const [rebate, setRebate] = useState<any>();
  const [isSalePriceExpired, setIsSalePriceExpired] = useState(false);

  const setTooltip = (text: any) => {
    if (!text || !text?.value) return null;
    if (typeof text === "string") return text;
    if (typeof text === "object" && text?.value) return text?.value;
  };

  const renderTooltip = (props: any) => (
    <Tooltip {...props}>{setTooltip(categoryTooltipText)}</Tooltip>
  );

  const redirectToOrderPage = async () => {
    await router.push(`${language === "en" ? "/en" : ""}/order/${id}`);
  };

  const sendOrder = () => {
    const submitButton = document.querySelector(
      ".submitButtonWrapper button"
    ) as HTMLElement | null;

    if (!submitButton) return;
    submitButton.click();
  };

  const renderOrderButton = () => (
    <Button
      onClick={isOnPrivateClasses ? sendOrder : redirectToOrderPage}
      variant={"primary"}
    >
      {translations[language].buttonJoin}
    </Button>
  );

  const redirectPrivateClasses = () => {
    const typeForm = defaultPrivateClassContext
      ? "privateClasses"
      : "examTraining";
    const dataToGenerate = defaultPrivateClassContext
      ? defaultPrivateClassContext
      : defaultExamTrainingContext;

    const { query, product_id } = generateNotesToUrl(dataToGenerate, typeForm);

    router.push({
      pathname: `${language === "en" ? "/en" : ""}/order/${product_id}`,
      query,
    });
  };

  const renderPrivateClassesButton = () => {
    let isDisabled = false;

    if (isOnExamTraining) {
      const { howPersonA1B1, howPersonB2C2 } = defaultExamTrainingContext;
      isDisabled = howPersonA1B1.length === 0 && howPersonB2C2.length === 0;
    }

    return (
      <Button
        onClick={redirectPrivateClasses}
        variant={"primary"}
        disabled={isDisabled}
      >
        {translations[language].bookPrivateClass}
      </Button>
    );
  };

  useEffect(() => {
    if (couponRebate) {
      const startValue = discountPrice ? discountPrice : normalPrice;

      switch (true) {
        case couponRebate?.type === REBATE_TYPES.percent:
          const rebateValuePercent = +startValue * couponRebate.value;
          const salePricePercent = +startValue - rebateValuePercent;

          setRebate({
            rebateValue: rebateValuePercent,
            salePrice: salePricePercent,
          });

          break;
        case couponRebate?.type === REBATE_TYPES.productFixed ||
          couponRebate?.type === REBATE_TYPES.cartFixed:
          const rebateValueFixedProduct = couponRebate.value;
          const salePriceFixedProduct = +startValue - rebateValueFixedProduct;

          setRebate({
            rebateValue: rebateValueFixedProduct,
            salePrice: salePriceFixedProduct,
          });

          break;
        default:
          break;
      }

      return;
    }

    setRebate({});
  }, [couponRebate]);

  useEffect(() => {
    if (discountPrice && salePriceExpiredDate && salePriceStartDate) {
      const today = new Date();
      const isExpired = new Date(salePriceExpiredDate) < today;

      setIsSalePriceExpired(isExpired);
      return;
    }

    setIsSalePriceExpired(false);
  }, []);

  return (
    <div
      className={
        isOnOrder || isOnPrivateClasses ? styles.rootVertical : styles.root
      }
    >
      <div className={styles.topLabelsWrapper}>
        <p className={styles.id}>ID: {id}</p>
        <div className={styles.statusInfoWrapper}>
          {isAvailable && stockQuantity && stockQuantity <= 3 ? (
            <StockAvailable type="latest" />
          ) : (
            <StockAvailable />
          )}
        </div>
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.firstColumn}>
          <h4 className={styles.title}>{title}</h4>
          <div className={styles.categoriesWrapper}>
            <p className={styles.category}>{category}</p>
            {setTooltip(categoryTooltipText) && (
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <Img src={infoTooltipIcon} />
              </OverlayTrigger>
            )}
          </div>
          <div
            className={cx(styles.dateWrapper, {
              [styles.alignCenter]: !registrationDate,
            })}
          >
            <Img src={calendarIcon} />
            <div className={styles.dates}>
              <p className={styles.rangeDate}>
                {dateFormater(startDate)} – {dateFormater(endDate)}
              </p>
              {/* {registrationDate && (
                <p className={styles.startDate}>
                  {translations[language].registrationStatrs}{" "}
                  {dateFormater(registrationDate)}
                </p>
              )} */}
            </div>
          </div>
          {!isOnOrder &&
            !isOnPrivateClasses &&
            !withoutButton &&
            renderOrderButton()}
        </div>

        <div className={styles.secondColumn}>
          {location && (
            <SingleInformation
              label={translations[language].location}
              text={location}
              additionalText={locationAdditionalText && locationAdditionalText}
            />
          )}
          {timetable && (
            <SingleInformation
              label={translations[language].timetable}
              text={timetable}
            />
          )}
        </div>

        <div className={cx(styles.thirdColumn, "thirdColumn")}>
          {normalPrice &&
            !discountPrice &&
            rebate &&
            Object.values(rebate).length > 0 && (
              <SinglePrice
                title={translations[language].coursePrice}
                price={normalPrice}
              />
            )}
          {normalPrice && (!rebate || Object.values(rebate).length === 0) && (
            <>
              <SinglePrice
                title={translations[language].coursePrice}
                price={normalPrice}
              />
              {examTrainingPersons === 2 && (
                <p className={styles.examTrainingPersonsInfo}>
                  {translations[language].pricePerPerson}{" "}
                  <strong>{+normalPrice / 2} EUR</strong>
                </p>
              )}
            </>
          )}
          {discountPrice && !isOnPrivateClasses && !isSalePriceExpired && (
            <SinglePrice
              isSalePrice
              title={
                salePriceLabel?.value
                  ? salePriceLabel.value
                  : translations[language].youWillPayNow
              }
              price={discountPrice}
            />
          )}
          {rebate && Object.values(rebate).length > 0 && (
            <>
              <SinglePrice
                isCoupon
                title={translations[language].couponCode}
                price={rebate.rebateValue}
              />
              <SinglePrice
                title={translations[language].finalPrice}
                price={rebate.salePrice}
              />
            </>
          )}

          {!isThankYouPage &&
            !isOnOrder &&
            !isOnPrivateClasses &&
            !isOnCoursesList &&
            renderOrderButton()}
          {!isThankYouPage &&
            isOnPrivateClasses &&
            renderPrivateClassesButton()}
        </div>
      </div>
    </div>
  );
};
export default AvailableCourse;
