import React, { useContext } from "react";
import cx from "classnames";

import styles from "./AvailableCourses.module.css";
import { translations } from "./translations";
import LanguageContext from "../LanguageContext";

interface IStockAvailableProps {
  type?: "latest" | "active";
}

const StockAvailable: React.FC<IStockAvailableProps> = ({
  type = "active",
}) => {
  const { language } = useContext(LanguageContext);

  if (type === "latest") {
    return (
      <>
        <div className={styles.circleLatest}></div>
        <p className={styles.status}>{translations[language].bookingLatest}</p>
      </>
    );
  }

  return (
    <>
      <div className={styles.circleActive}></div>
      <p className={styles.status}>{translations[language].bookingOpen}</p>
    </>
  );
};
export default StockAvailable;
