import React from "react";
import cx from "classnames";

import styles from "./AvailableCourses.module.css";

interface ISinglePrice {
  title: string;
  price?: string;
  isCoupon?: boolean;
  isSalePrice?: boolean;
}

const SinglePrice: React.FC<ISinglePrice> = ({
  title,
  price,
  isCoupon = false,
  isSalePrice = false,
}) => {
  return (
    <div
      className={cx(styles.singlePriceWrapper, {
        [styles.singlePriceWrapperCoupon]: isCoupon,
        [styles.singlePriceWrapperSale]: isSalePrice,
      })}
    >
      <p className={styles.priceLabel}>{title}</p>
      <h5
        className={cx(styles.priceValue, { [styles.isSalePrice]: isSalePrice })}
      >
        {isCoupon && "-"}
        {price} EUR
      </h5>
    </div>
  );
};
export default SinglePrice;
