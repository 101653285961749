import React, { useContext, useEffect, useState } from "react";
import { Form, InputGroup, Alert } from "react-bootstrap";
import { useFormik, Field, FormikProvider } from "formik";
import { WHAT_WANT_LEARN } from "./PrivateClassesFormData";
import {
  CURRENT_LEVEL,
  LANGUAGES,
  YOUR_AVAILABILITY,
} from "../ModalForms/FindCourseForm/FindCourseFormData";
import { COURSE_PLACE } from "../ModalForms/EstimateB2BForm/EstimateB2BFormData";
import Button from "../elements/Button";
import CheckboxForms from "../ModalForms/CheckboxForms/CheckboxForms";
import LanguageContext from "../LanguageContext";

import styles from "../ModalForms/ModalForms.module.css";
import Link from "next/link";
import { PrivateClassContext } from "../PrivateClasses/PrivateClasses";
import PlusMinutInput from "../ModalForms/PlusMinusForms/PlusMinutInput";
import { useRouter } from "next/router";
import { translations } from "../ModalForms/translations";
import CheckboxWithInput from "../ModalForms/CheckboxWithInput/CheckboxWithInput";

const PrivateClassesForm = () => {
  const router = useRouter();
  const { language } = useContext(LanguageContext);
  const { defaultContext, setDefaultContext } = useContext(PrivateClassContext);

  const [requestStatus, setRequestStatus] = React.useState<{
    status: string;
    message: string;
  } | null>(null);

  const formik = useFormik({
    initialValues: {
      whichLanguageYouWant: "",
      whatLearn: "",
      yourCurrentLevel: "",
      coursePlace: "",
      howOften: "1",
      howOftenDays: "",
      howOftenHours: "",
      preferedNumber: "5",
      startDate: "",
      endDate: "",
    },
    enableReinitialize: true,
    onSubmit: async (data, actions) => {
      try {
        actions.setSubmitting(true);
        actions.resetForm();
        actions.setSubmitting(false);
      } catch (e) {
        setRequestStatus({
          status: "failed",
          message: "error",
        });
      }
    },
  });

  const whatLearnUpdateContext = (value: any, name: string) => {
    if (defaultContext[name].includes(value.target.value)) {
      setDefaultContext((prevState: any) => ({
        ...prevState,
        [name]: [...prevState[name]].filter((e) => e !== value.target.value),
      }));

      return;
    }

    switch (true) {
      case typeof defaultContext[name] === "string":
        setDefaultContext((prevState: any) => ({
          ...prevState,
          [name]: [prevState[name], value.target.value],
        }));

        break;
      case defaultContext[name].length > 1:
        setDefaultContext((prevState: any) => ({
          ...prevState,
          [name]: [...prevState[name], value.target.value],
        }));

        break;
      default:
        setDefaultContext((prevState: any) => ({
          ...prevState,
          [name]: value.target.value,
        }));
    }
  };

  const contextUpdate = (value: any, name: string) => {
    formik.handleChange(value);

    if (name === "whatLearn") {
      whatLearnUpdateContext(value, name);
      return;
    }

    setDefaultContext((prevState: any) => ({
      ...prevState,
      [name]: value.target.value,
    }));
  };

  return (
    <FormikProvider value={formik}>
      <div className={styles.estimateTitleWrapper}>
        <h2 className={styles.modalTitle}>
          {translations[language].privateClassesTitle}
        </h2>
      </div>

      <Form onSubmit={formik.handleSubmit}>
        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.bigFormLabel}>
            1. {translations[language].languageInterested}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            {LANGUAGES("privateClass").map((place) => (
              <Field
                name="whichLanguageYouWant"
                id="check"
                label={place}
                value={place}
                component={CheckboxForms}
              />
            ))}
            <Field
              name="whichLanguageYouWant"
              id="whichLanguageYouWant"
              label={translations[language].other}
              value={translations[language].other}
              component={CheckboxWithInput}
            />
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label className={styles.bigFormLabel}>
            2. {translations[language].whatYouLearn}
          </Form.Label>
          <div className={styles.estimateCheckboxWrapper}>
            {WHAT_WANT_LEARN().map((checkbox) => (
              <label>
                <Field
                  type="checkbox"
                  name="whatLearn"
                  value={checkbox}
                  onChange={(e: any) => contextUpdate(e, "whatLearn")}
                />
                {checkbox}
              </label>
            ))}
          </div>
        </Form.Group>
        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.bigFormLabel}>
            3. {translations[language].currentLevel}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            {CURRENT_LEVEL.map((system) => (
              <Field
                name="yourCurrentLevel"
                id="check"
                label={system}
                value={system}
                isRadio
                component={CheckboxForms}
              />
            ))}
          </div>
          <p className={styles.additionalInfoGroup}>
            {translations[language].notKnowYourLevel}{" "}
            <Link href="#verify_level">
              {translations[language].verifyYourLevel}
            </Link>
          </p>
        </Form.Group>
        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.bigFormLabel}>
            4. {translations[language].coursePlace}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            {COURSE_PLACE().map((system) => (
              <Field
                name="coursePlace"
                id="check"
                label={system}
                value={system}
                isRadio
                component={CheckboxForms}
              />
            ))}
          </div>
        </Form.Group>

        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.bigFormLabel}>
            5. {translations[language].howOften}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            <Field
              name="howOften"
              id="check"
              label={formik.values.howOften}
              value={formik.values.howOften}
              component={PlusMinutInput}
            />
          </div>
        </Form.Group>

        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.smallFormLabel}>
            {translations[language].chooseDays}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            {YOUR_AVAILABILITY().map((place) => (
              <Field
                name="howOftenDays"
                id="check"
                label={place}
                value={place}
                component={CheckboxForms}
              />
            ))}
          </div>

          <Form.Label className={styles.smallFormLabel}>
            {translations[language].chooseHours}
          </Form.Label>
          <div className={styles.radiosWrapper}>
            {["08:00-12:00", "12:00-16:00", "16:00-20:00"].map((place) => (
              <Field
                name="howOftenHours"
                id="check"
                label={place}
                value={place}
                isRadio
                component={CheckboxForms}
              />
            ))}
          </div>
        </Form.Group>
        <Form.Group
          className={styles.estimateWrapper}
          controlId="formBasicRange"
        >
          <Form.Label className={styles.bigFormLabel}>
            6. {translations[language].preferedUnits}
          </Form.Label>
          <div className={styles.rangeWrapper}>
            <Form.Control
              type="range"
              min={5}
              max={20}
              name="preferedNumber"
              onChange={(e) => contextUpdate(e, "preferedNumber")}
              value={formik.values.preferedNumber}
            />
            <div className={styles.rangeValueBox}>
              {formik.values.preferedNumber}
            </div>
          </div>
          <p className={styles.additionalInfoGroup}>
            {translations[language].unitsValidateInfo}
            <br />
            {translations[language].youNeedMore}{" "}
            <Link
              href={
                language === "en"
                  ? "/en/german-language-school-lab-vienna-contact-us"
                  : "/kontakt-lcl-sprachschule-deutsch-sprachen-labor"
              }
            >
              {translations[language].contactUs}
            </Link>{" "}
            {translations[language].tailoredLearning}
          </p>
        </Form.Group>

        <Form.Group className={styles.estimateWrapper} controlId="formReason">
          <Form.Label className={styles.bigFormLabel}>
            7. {translations[language].startDate}
          </Form.Label>
          <div className={styles.datesWrapper}>
            <Form.Control
              type="date"
              name="startDate"
              onChange={(e) => contextUpdate(e, "startDate")}
              value={formik.values.startDate}
            />
            -
            <Form.Control
              type="date"
              name="endDate"
              onChange={(e) => contextUpdate(e, "endDate")}
              value={formik.values.endDate}
            />
          </div>
        </Form.Group>
      </Form>
    </FormikProvider>
  );
};

export default PrivateClassesForm;
