import React, { useContext } from "react";
import LanguageContext from "../LanguageContext";
import { translations } from "../ModalForms/translations";

type SelectedLanguage = string[];

export const LANGUAGES_EXAM = () => {
  const { language } = useContext(LanguageContext);

  return [translations[language].german, translations[language].english];
};

export const SELECTED_LANGUAGE_DETAILS = () => {
  const { language } = useContext(LanguageContext);

  const valuesObj = {
    [translations[language].german]: ["ÖIF", "ÖSD", "EPD", "Goethe", "telc"],
    [translations[language].english]: [
      "Cambridge",
      "TOEFL",
      "IELTS",
      "GMAT",
      "SAT",
      "GRE",
      "TOEIC",
    ],
  };

  return valuesObj;
};

export const WHAT_WANT_LEARN_EXAM = () => {
  const { language } = useContext(LanguageContext);

  return translations[language].examTrainingLearnTopics;
};

export const EXAM_TRAINING_DAYS = () => {
  const { language } = useContext(LanguageContext);

  return [
    translations[language].monday,
    translations[language].tuesday,
    translations[language].wednesday,
    translations[language].thursday,
    translations[language].fridayWeekend,
  ];
};
