import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    registrationStatrs: "Registration starts:",
    buttonJoin: "Purchase now",
    bookingOpen: "Booking open",
    bookingLatest: "Last call",
    location: "Location:",
    timetable: "Timetable:",
    coursePrice: "Course price:",
    youWillPayNow: "You will pay now:",
    finalPrice: "Final price:",
    couponCode: "Coupon code:",
    availableDates: "Available course dates",
    seeOnCalendar: "See on the calendar",
    additionalPriceInfo:
      "You need to pay 10% of course price upon registration, and 90% after we confirm your booking.",
    bookPrivateClass: "Book private classes",
    note: "Note:",
    beforeJoining:
      "Before joinig a course, your application needs to be reviewed.",
    inCaseOfNotMeet:
      "In case of not meting the requirements for joining a course, booking fee will be returned to you.",
    pricePerPerson: "Price per person",
  },
  [Languages.German]: {
    registrationStatrs: "Anmeldungsbeginn:",
    buttonJoin: "Zahlungspflichtig buchen",
    bookingOpen: "Buchung offen",
    bookingLatest: "Letzte verfügbare Plätze",
    location: "Ort:",
    timetable: "Stundenplan:",
    coursePrice: "Kurspreis:",
    youWillPayNow: "Bei Frühbuchung:",
    finalPrice: "Endgültiger Preis:",
    couponCode: "Gutscheincode:",
    availableDates: "Verfügbare Kurstermine",
    seeOnCalendar: "Siehe im Kalender",
    additionalPriceInfo:
      "Sie müssen 10 % des Kurspreises bei der Anmeldung bezahlen und 90 % nachdem wir Ihre Buchung bestätigt haben.",
    bookPrivateClass: "Kostenpflichtig buchen",
    note: "Notiz:",
    beforeJoining:
      "Bevor Sie an einem Kurs teilnehmen, muss Ihre Bewerbung geprüft werden.",
    inCaseOfNotMeet:
      "Falls Sie die Voraussetzungen für die Teilnahme an einem Kurs nicht erfüllen, wird Ihnen die Buchungsgebühr zurückerstattet.",
    pricePerPerson: "Preis pro Person",
  },
};

export { translations };
