import React, { useContext, useEffect, useRef, useState } from "react";
import { ExamTrainingContext } from "../../ExamTraining/ExamTraining";
import { PrivateClassContext } from "../../PrivateClasses/PrivateClasses";

import styles from "../ModalForms.module.css";
import cx from "classnames";
import { translations } from "../translations";
import LanguageContext from "../../LanguageContext";

const CheckboxWithInput: React.FC<any> = ({
  customType = "private-class",
  form: { setFieldValue, values },
  label,
  value,
  field,
  id,
  isRadio,
  disabled,
}) => {
  const { language } = useContext(LanguageContext);
  const { setDefaultContext } = useContext(PrivateClassContext);
  const { setDefaultContext: setDefaultContextExamTraining } =
    useContext(ExamTrainingContext);

  const checkboxFormsInput = useRef(null);
  const checkboxFormsButton = useRef(null);
  const [inputWidth, setInputWidth] = useState(0);

  const [inputValue, setInputValue] = useState("Sprache: ");
  const [isChecked, setIsChecked] = useState(false);

  const contextSetter =
    customType === "private-class"
      ? setDefaultContext
      : setDefaultContextExamTraining;

  const onChangeCheckBox = (e: any) => {
    setIsChecked(e.target.checked);

    if (
      values[field.name].length > 0 &&
      values[field.name].some((elem: string) =>
        elem.includes(translations[language].other)
      )
    ) {
      const newValue = values[field.name].filter(
        (elem: string) => !elem.includes(translations[language].other)
      );

      setFieldValue(field.name, newValue);
      contextSetter((prevState: any) => ({
        ...prevState,
        [field.name]: newValue,
      }));
    }
  };

  useEffect(() => {
    if (!checkboxFormsButton.current) return;

    // @ts-expect-error
    setInputWidth(checkboxFormsButton.current.offsetWidth);
  }, [checkboxFormsButton]);

  const setMultipleValues = (e: any) => {
    setFieldValue(field.name, [
      ...values[field.name],
      `${translations[language].other} - ${e.target.value}`,
    ]);
    contextSetter((prevState: any) => ({
      ...prevState,
      [field.name]: [
        ...values[field.name],
        `${translations[language].other} - ${e.target.value}`,
      ],
    }));
  };

  return (
    <div>
      <label
        htmlFor={label + id}
        className={styles.rootRadioForms}
        style={{ width: inputWidth }}
      >
        <input
          className={styles.radioFormsInput}
          ref={checkboxFormsInput}
          id={label + id}
          checked={isChecked}
          type="checkbox"
          onChange={onChangeCheckBox}
        />
        <span ref={checkboxFormsButton} className={styles.radioFormsButton}>
          {label}
          {isChecked && <span className={styles.radioFormsButtonClose}>x</span>}
        </span>
      </label>
      {isChecked && (
        <input
          className={cx(styles.formInput, styles.formInputWithCheckbox)}
          type="text"
          name={id}
          defaultValue={inputValue}
          onBlur={setMultipleValues}
        />
      )}
    </div>
  );
};

export default CheckboxWithInput;
