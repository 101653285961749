export const getDateValues = (date: string) => {
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);

  return `${day}.${month}.${year}`;
};

export const dateFormater = (date = "") => {
  if (date.length === 0) return;

  if (date.includes("-")) {
    const fixedDate = date.split("-").join("");
    return getDateValues(fixedDate);
  }

  return getDateValues(date);
};

export const checkIsLastFourteenDays = (endDate: string) => {
  const year = endDate.substring(0, 4);
  const month = endDate.substring(4, 6);
  const day = endDate.substring(6, 8);

  const today = new Date().getTime();
  const courseExpiredDate = new Date(+year, +month - 1, +day).getTime();
  const difference = courseExpiredDate - today;
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

  return totalDays;
};
