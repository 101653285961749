import { useContext } from "react";
import LanguageContext from "../../LanguageContext";
import { translations } from "../translations";

type LanguagePropsType = "default" | "privateClass";

export const LANGUAGES = (type: LanguagePropsType = "default") => {
  const { language } = useContext(LanguageContext);
  const values = [
    translations[language].german,
    translations[language].english,
    translations[language].french,
    translations[language].spanish,
  ];

  return values;
};

export const CURRENT_LEVEL = ["A1", "A2", "B1", "B1/B2", "B2", "B2/C1", "C1"];

export const WHAT_APPLY_TO_YOU = () => {
  const { language } = useContext(LanguageContext);

  return [
    translations[language].speaking,
    translations[language].writing,
    translations[language].grammar,
    translations[language].examPreparation,
    translations[language].levelQuickly,
  ];
};

export const YOUR_AVAILABILITY = () => {
  const { language } = useContext(LanguageContext);

  return [
    translations[language].monday,
    translations[language].tuesday,
    translations[language].wednesday,
    translations[language].thursday,
    translations[language].friday,
    translations[language].saturday,
    translations[language].sunday,
  ];
};

export const OPERATING_MODES = () => {
  const { language } = useContext(LanguageContext);

  return [
    translations[language].online,
    translations[language].onsite,
    translations[language].inLcl,
  ];
};
